
.hero-title {
  .hero-text {
    font-size: 700%;
    font-family: "MoonFlower";
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
.pe-none {
  pointer-events: none;
}
.title-outer-container {
  margin-bottom: 5em;
}
.content {
  margin: 0px 10rem 10rem;
  @media only screen and (max-width: 1200px) {
    margin: 0px 5rem 8rem;
  }
  @media only screen and (max-width: 767px) {
    margin: 0px 1rem 6rem;
  }
}
.drawings-carrousel {
  position: relative;
  .carrousel-page {
    z-index: 1;
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 20em 20em;
    column-gap: 2em;
    row-gap: 2em;
    @media only screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 20em 20em 20em 20em;
    }
    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    .page-item {
      cursor: pointer;
    }
  }
  .carrousel-controls {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    @media only screen and (max-width: 767px) {
      position: unset;
      display: flex;
      justify-content: center;
      padding-top: 1em;
    }
    .carrousel-control {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 0;
      bottom: 0;
      font-size: 5em;
      color: #41403b;
      transform: translate(140%, 0px);
      filter: drop-shadow(3px 3px 3px rgb(194, 194, 194));
      transition: transform 0.2s ease;
      // transition: drop-shadow 0.2s ease;
      @media only screen and (max-width: 767px) {
        position: unset;
      }
      &.disabled {
        opacity: 0.2;
      }
      &:hover {
        transform: translate(140%, 0px) scale(1.1);
        // filter: drop-shadow(3px 3px 5px rgb(152, 152, 152));
        cursor: pointer;
      }
      &:active {
        transform: translate(140%, 0px) scale(0.9);
      }
      &.left {
        right: unset;
        left: 0;
        transform: translate(-140%, 0px) rotate(180deg);
        filter: drop-shadow(-3px -3px 3px rgb(194, 194, 194));
        &:hover {
          transform: translate(-140%, 0px) rotate(180deg) scale(1.1);
          // filter: drop-shadow(3px 3px 5px rgb(152, 152, 152));
          cursor: pointer;
        }
        &:active {
          transform: translate(-140%, 0px) rotate(180deg) scale(0.9);
        }
      }
    }
  }
}
.searchbar-container {
  margin-bottom: 2rem;
  display: flex;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  .orderby-container {
    width: 40%;
    @media only screen and (max-width: 1200px) {
      width: unset;
    }
  }
  .filter-container {
    width: 35%;
    margin-right: 0.5em;
    @media only screen and (max-width: 1200px) {
      width: unset;
    }
  }
}
