header {
  &.homepage {
    font-family: "MoonFlower";
    font-size: 1.2em;
    cursor: default;
    @media only screen and (max-width: $desktop-med) {
      font-size: 0.7em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 0.6em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 0.35em;
    }
    .title-container {
      top: 5%;
      left: 0;
      bottom: 20%;
      right: 0;
      padding: 10em 10% 10em 25%;
      text-align: right;
      font-weight: bold;
      line-height: 1.25;
      @media only screen and (max-width: $mobile) {
        padding: 40em 1em 20em 1em;
        background-size: contain !important;
        background-position: left 20% !important;
      }
      .title {
        font-size: 1200%;
        letter-spacing: 0.1em;
        word-break: break-word;
        color: $light-green;
      }
      .subtitle {
        font-size: 400%;
        letter-spacing: 0.2em;
        margin-top: 0px;
        // white-space: nowrap;
        color: $purple;
        @media only screen and (max-width: $mobile) {
          white-space: unset;
        }
      }
    }
  }
  &.bases {
    font-family: "MoonFlower";
    font-size: 1.15em;
    cursor: default;
    @media only screen and (max-width: $desktop-med) {
      font-size: 0.7em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 0.6em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 0.4em;
    }
    .title-container {
      min-height: 65em;
      padding: 10em 10% 10em 25%;
      text-align: right;
      font-weight: bold;
      line-height: 1;
      @media only screen and (max-width: $desktop-med) {
        padding: 6em 10% 10em 25%;
      }
      @media only screen and (max-width: $tablet) {
        padding: 6em 10% 10em 25%;
      }
      @media only screen and (max-width: $mobile) {
        padding: 50em 1em 8em 1em;
        // height: 90em;
      }
      .title {
        font-size: 1200%;
        letter-spacing: 0.1em;
        word-break: break-word;
        color: $pink;
        line-height: 1;
      }
      .subtitle {
        font-family: "AvenirNext";
        font-size: 200%;
        font-weight: normal;
        color: $gray;
        padding-right: 0.9em;
        margin-top: 0px;
        @media only screen and (max-width: $mobile) {
          font-size: 250%;
        }
      }
    }
  }
  &.jurado {
    font-family: "MoonFlower";
    font-size: 1em;
    cursor: default;
    @media only screen and (max-width: $desktop-med) {
      font-size: 0.7em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 0.6em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 0.4em;
    }
    .title-container {
      height: 110vh;
      padding: 20em 10% 10em 25%;
      text-align: right;
      font-weight: bold;
      line-height: 1.25;
      @media only screen and (max-width: $mobile) {
        height: 100vh;
        padding: 60vh 1em 10em 1em;
      }
      .title {
        font-size: 1200%;
        letter-spacing: 0.1em;
        word-break: break-word;
        color: $purple;
      }
    }
  }
  &.dibujos {
    font-family: "MoonFlower";
    font-size: 1em;
    cursor: default;
    @media only screen and (max-width: $desktop-med) {
      font-size: 0.8em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 0.6em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 0.4em;
    }
    .title-container {
      height: 110vh;
      padding: 20em 10% 10em 25%;
      text-align: right;
      font-weight: bold;
      line-height: 1.25;
      @media only screen and (max-width: $tablet) {
        padding: 20em 1em 10em 1em;
        height: 60vh;
      }
      @media only screen and (max-width: $mobile) {
        height: 40vh;
        padding: 25em 1em 10em 1em;
      }
      .title {
        font-size: 1200%;
        letter-spacing: 0.1em;
        word-break: break-word;
        color: $light-green;
      }
    }
  }
  &.subir {
    font-family: "MoonFlower";
    font-size: 1em;
    cursor: default;
    @media only screen and (max-width: $desktop-med) {
      font-size: 0.8em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 0.6em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 0.3em;
    }
    .title-container {
      height: 90vh;
      padding: 20em 10% 10em 40%;
      text-align: right;
      font-weight: bold;
      line-height: 1.25;
      @media only screen and (max-width: $desktop-med) {
        padding: 15em 10% 10em 40%;
      }
      @media only screen and (max-width: $tablet) {
        padding: 20em 10% 10em 40%;
        height: 60vh;
      }
      @media only screen and (max-width: $mobile) {
        height: 40vh;
        padding: 55em 1em 1em 1em;
        margin-bottom: 20em;
      }
      .title {
        font-size: 1200%;
        letter-spacing: 0.1em;
        word-break: break-word;
        color: $brown;
      }
    }
  }
}
