
$avatar-size: 5em;
.bottom-right {
  right: 1em;
  bottom: 0;
}
.bottom-center {
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
.avatar-container {
  padding: 1em;
  overflow: hidden;
  border-radius: 50%;
  background-color: white;
  transform: scale(1);
  .avatar {
    height: $avatar-size;
    width: $avatar-size;
  }
}
.actions-container {
  display: flex;
  flex-direction: column;
  transform: scale(1);
  .username {
    padding: 0.5em 0em;
    text-align: center;
  }
}
.user-sidebar {
  cursor: pointer;
  padding: 1em 2em 2em;
  background-color: lightgray;
  border-radius: 15px 15px 0px 0px;
  box-shadow: #545454c7 1px 1px 11px 2px;
  &:hover {
    .bottom-spacer {
      height: 1em;
    }
  }
  .bottom-spacer {
    height: 0px;
    transition: height 0.2s ease;
  }
}
