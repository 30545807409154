
$icon-size: 5em;

.menu-sidebar {
  a {
    color: inherit;
    &.active {
      text-decoration: underline;
    }
  }
}

.menu-container {
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media only screen and (max-width: 1100px) {
    top: auto;
    bottom: 0px;
    right: 0px;
    left: 0px;
    flex-direction: row;
    height: 5rem;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    justify-content: space-evenly;
    background-color: #ffffff91;
  }
  .menu-toggle {
    margin-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    @media only screen and (max-width: 1100px) {
      margin-bottom: unset;
    }
    .icon {
      width: $icon-size;
      height: $icon-size;
      filter: drop-shadow(0px 2px 2px #222222);
      @media only screen and (max-width: 1100px) {
        height: 100%;
      }
    }
  }
}
.avatar-container {
  .avatar {
    height: 100%;
    width: 100%;
  }
}
.sidebar-avatar-container {
  display: flex;
  justify-content: center;
  .avatar-ring {
    position: relative;
    padding: 2em;
    border-radius: 50%;
    background-color: #80808030;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #80808046;
      .switch-icon-container {
        opacity: 1;
      }
    }
    .avatar-container {
      height: 10rem;
      width: 10rem;
    }
    .switch-icon-container {
      position: absolute;
      transition: opacity 0.3s ease;
      opacity: 0;
      bottom: 0;
      right: 0;
      font-size: 160%;
      line-height: 100%;
      @media only screen and (max-width: 1100px) {
        opacity: 1;
      }
    }
  }
}
.text-container {
  text-align: center;
  width: $icon-size;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  @media only screen and (max-width: 1100px) {
    padding-bottom: 0.2em;
  }
}
.grayscale {
  filter: grayscale(1);
}
.menu-user-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .user-data {
    text-align: center;
    font-size: 120%;
    overflow: hidden;
    text-overflow: ellipsis;
    &.name-container {
      padding-top: 1em;
      font-weight: bold;
    }
    // &.email-container {
    // }
  }
  .actions-container {
    padding-top: 2em;
    .action-container {
      display: flex;
      justify-content: center;
      a {
        font-size: 150%;
        color: #5e4996;
        &.active {
          text-decoration: underline;
        }
      }
    }
  }
}

body {
  &.modal-open {
    @media screen and (min-width: 1200px) {
      .menu-container {
        padding-right: 17px;
      }
    }
  }
}
