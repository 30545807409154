
.overflow-hidden {
  overflow: hidden;
}
.list-item-container {
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 25em;
  margin-bottom: 2rem;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
  .img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .img-container {
    flex: 0 0 auto;
    position: relative;
    width: 40%;
    height: 100%;
    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }
  .info-container {
    flex: 1 1 auto;
    padding: 1em;
    font-size: 150%;
    .data-container {
      margin-bottom: 0.5em;
      .title {
        font-size: 60%;
        color: #5c5b5b;
      }
      // .data {
      // }
      .status-pill {
        padding: .5em .2em;
        border-radius: 50%;
      }
    }
  }
}
.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    width: 30%;
    height: 30%;
  }
}
