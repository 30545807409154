
.hero-title {
  .hero-text {
    font-size: 700%;
    font-family: "MoonFlower";
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
.btn:hover,
.btn:active,
.btn:focus {
  color: unset;
  background-color: unset !important;
  text-decoration: none !important;
  box-shadow: none !important;
}
