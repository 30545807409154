//breakpoints
$desktop-med: 1441px;
$tablet: 1025px;
$mobile: 767px;

//colors
$light-green: #bccb64;
$purple: #5e4996;
$green: #478248;
$gray: #4c4c4c;
$orange: #e49758;
$light-blue: #a3d5e9;
$pink: #e466a2;
$brown: #584a45;
$yellow: #f4e274;
$red: #d05041;