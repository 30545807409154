
























































































































.custom-file-label {
  color: #6c757d;
  font-size: 90%;
}

.custom-modal-header {
  font-size: 150%;
  border: none;
}
.custom-modal-content {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
    font-size: 130%;
  }
}
.btn:hover,
.btn:active,
.btn:focus {
  color: unset;
  background-color: unset !important;
  text-decoration: none !important;
  box-shadow: none !important;
}
