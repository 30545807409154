
$desktop-med: 1441px;
$tablet: 1025px;
$mobile: 767px;
.content {
  &.home {
    font-size: 1.2em;
    padding-bottom: 0px;
    @media only screen and (max-width: $desktop-med) {
      font-size: 0.75em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 0.6em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 0.6em;
    }
    .content-outer {
      width: 100%;
      padding: 0px 10em;
      @media only screen and (max-width: $tablet) {
        padding-left: 5em;
        padding-right: 5em;
      }
      @media only screen and (max-width: $mobile) {
        padding-right: 0em;
        padding-left: 0em;
      }
      .content-inner {
        // width: 66%;
        // padding-right: 20%;
        font-size: 160%;
        @media only screen and (max-width: $mobile) {
          padding: 0em 1em;
          width: 100%;
          padding-bottom: 3em;
          // background-color: #ffffffba;
        }
        .charter-roman {
          font-family: "CharterRoman";
        }
        .banners {
          .title {
            font-size: 250%;
            line-height: 120%;
            text-align: center;
            margin-bottom: 1.5em;
          }
          p {
            font-family: "MoonFlower";
            line-height: 130%;
            font-size: 200%;
          }
        }
        .end-image-container {
          .content-actions {
            width: 60%;
            margin-left: auto;
            padding: 10em 0em 0em;
            @media only screen and (max-width: $tablet) {
              width: 100%;
            }
            @media only screen and (max-width: $mobile) {
              padding: 20em 0em 0em;
            }
          }
        }
      }
    }
  }
}

.logos-container {
  width: 100%;
  display: flex;
  padding: 1em 10em 0px;
  background-color: white;
  margin-top: 5em;
  @media only screen and (max-width: $tablet) {
    padding-left: 5em;
    padding-right: 5em;
  }
  @media only screen and (max-width: $mobile) {
    padding-right: 0em;
    padding-left: 0em;
  }
  .logo-item {
    flex: 1 1 auto;
    height: 10em;
  }
}

.btn:hover,
.btn:active,
.btn:focus {
  color: unset;
  background-color: unset !important;
  text-decoration: none !important;
  box-shadow: none !important;
}
