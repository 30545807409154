
.title-container {
    @media only screen and (max-width: 767px) {
      background-size: 70% !important;
      background-position: top  left!important;
  }
  .extra-subtitle {
    font-size: 300%;
    padding: 0.5em 0px;
  }
}
