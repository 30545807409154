body {
  font-family: "AvenirNext";
  overflow-x: hidden;
}

.bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.bg-img.cover {
  background-size: cover;
}

.hover-scale-1 {
  transition: 0.2s transform ease;
}
.hover-scale-1:hover {
  transform: scale(1.1);
}
.hover-scale-1:active {
  transform: scale(0.9);
}

header.homepage {
  font-family: "MoonFlower";
  font-size: 1.2em;
  cursor: default;
}
@media only screen and (max-width: 1441px) {
  header.homepage {
    font-size: 0.7em;
  }
}
@media only screen and (max-width: 1025px) {
  header.homepage {
    font-size: 0.6em;
  }
}
@media only screen and (max-width: 767px) {
  header.homepage {
    font-size: 0.35em;
  }
}
header.homepage .title-container {
  top: 5%;
  left: 0;
  bottom: 20%;
  right: 0;
  padding: 10em 10% 10em 25%;
  text-align: right;
  font-weight: bold;
  line-height: 1.25;
}
@media only screen and (max-width: 767px) {
  header.homepage .title-container {
    padding: 40em 1em 20em 1em;
    background-size: contain !important;
    background-position: left 20% !important;
  }
}
header.homepage .title-container .title {
  font-size: 1200%;
  letter-spacing: 0.1em;
  word-break: break-word;
  color: #bccb64;
}
header.homepage .title-container .subtitle {
  font-size: 400%;
  letter-spacing: 0.2em;
  margin-top: 0px;
  color: #5e4996;
}
@media only screen and (max-width: 767px) {
  header.homepage .title-container .subtitle {
    white-space: unset;
  }
}
header.bases {
  font-family: "MoonFlower";
  font-size: 1.15em;
  cursor: default;
}
@media only screen and (max-width: 1441px) {
  header.bases {
    font-size: 0.7em;
  }
}
@media only screen and (max-width: 1025px) {
  header.bases {
    font-size: 0.6em;
  }
}
@media only screen and (max-width: 767px) {
  header.bases {
    font-size: 0.4em;
  }
}
header.bases .title-container {
  min-height: 65em;
  padding: 10em 10% 10em 25%;
  text-align: right;
  font-weight: bold;
  line-height: 1;
}
@media only screen and (max-width: 1441px) {
  header.bases .title-container {
    padding: 6em 10% 10em 25%;
  }
}
@media only screen and (max-width: 1025px) {
  header.bases .title-container {
    padding: 6em 10% 10em 25%;
  }
}
@media only screen and (max-width: 767px) {
  header.bases .title-container {
    padding: 50em 1em 8em 1em;
  }
}
header.bases .title-container .title {
  font-size: 1200%;
  letter-spacing: 0.1em;
  word-break: break-word;
  color: #e466a2;
  line-height: 1;
}
header.bases .title-container .subtitle {
  font-family: "AvenirNext";
  font-size: 200%;
  font-weight: normal;
  color: #4c4c4c;
  padding-right: 0.9em;
  margin-top: 0px;
}
@media only screen and (max-width: 767px) {
  header.bases .title-container .subtitle {
    font-size: 250%;
  }
}
header.jurado {
  font-family: "MoonFlower";
  font-size: 1em;
  cursor: default;
}
@media only screen and (max-width: 1441px) {
  header.jurado {
    font-size: 0.7em;
  }
}
@media only screen and (max-width: 1025px) {
  header.jurado {
    font-size: 0.6em;
  }
}
@media only screen and (max-width: 767px) {
  header.jurado {
    font-size: 0.4em;
  }
}
header.jurado .title-container {
  height: 110vh;
  padding: 20em 10% 10em 25%;
  text-align: right;
  font-weight: bold;
  line-height: 1.25;
}
@media only screen and (max-width: 767px) {
  header.jurado .title-container {
    height: 100vh;
    padding: 60vh 1em 10em 1em;
  }
}
header.jurado .title-container .title {
  font-size: 1200%;
  letter-spacing: 0.1em;
  word-break: break-word;
  color: #5e4996;
}
header.dibujos {
  font-family: "MoonFlower";
  font-size: 1em;
  cursor: default;
}
@media only screen and (max-width: 1441px) {
  header.dibujos {
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 1025px) {
  header.dibujos {
    font-size: 0.6em;
  }
}
@media only screen and (max-width: 767px) {
  header.dibujos {
    font-size: 0.4em;
  }
}
header.dibujos .title-container {
  height: 110vh;
  padding: 20em 10% 10em 25%;
  text-align: right;
  font-weight: bold;
  line-height: 1.25;
}
@media only screen and (max-width: 1025px) {
  header.dibujos .title-container {
    padding: 20em 1em 10em 1em;
    height: 60vh;
  }
}
@media only screen and (max-width: 767px) {
  header.dibujos .title-container {
    height: 40vh;
    padding: 25em 1em 10em 1em;
  }
}
header.dibujos .title-container .title {
  font-size: 1200%;
  letter-spacing: 0.1em;
  word-break: break-word;
  color: #bccb64;
}
header.subir {
  font-family: "MoonFlower";
  font-size: 1em;
  cursor: default;
}
@media only screen and (max-width: 1441px) {
  header.subir {
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 1025px) {
  header.subir {
    font-size: 0.6em;
  }
}
@media only screen and (max-width: 767px) {
  header.subir {
    font-size: 0.3em;
  }
}
header.subir .title-container {
  height: 90vh;
  padding: 20em 10% 10em 40%;
  text-align: right;
  font-weight: bold;
  line-height: 1.25;
}
@media only screen and (max-width: 1441px) {
  header.subir .title-container {
    padding: 15em 10% 10em 40%;
  }
}
@media only screen and (max-width: 1025px) {
  header.subir .title-container {
    padding: 20em 10% 10em 40%;
    height: 60vh;
  }
}
@media only screen and (max-width: 767px) {
  header.subir .title-container {
    height: 40vh;
    padding: 55em 1em 1em 1em;
    margin-bottom: 20em;
  }
}
header.subir .title-container .title {
  font-size: 1200%;
  letter-spacing: 0.1em;
  word-break: break-word;
  color: #584a45;
}

.menu-sidebar {
  font-size: 1.5em;
}
@media only screen and (max-width: 1441px) {
  .menu-sidebar {
    font-size: 1.5em;
  }
}
@media only screen and (max-width: 1025px) {
  .menu-sidebar {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767px) {
  .menu-sidebar {
    font-size: 1em;
  }
}
.menu-sidebar a {
  font-size: 200%;
  color: #bccb64;
}
.menu-sidebar a:hover {
  text-decoration: underline;
}

.content.bases {
  font-size: 1.6em;
  padding-bottom: 0px;
  font-family: "AvenirNext";
}
@media only screen and (max-width: 1441px) {
  .content.bases {
    font-size: 1.25em;
  }
}
@media only screen and (max-width: 1025px) {
  .content.bases {
    font-size: 1.25em;
  }
}
@media only screen and (max-width: 767px) {
  .content.bases {
    font-size: 1em;
  }
}
.content.bases .hero-title {
  font-family: "MoonFlower";
  font-size: 110%;
  font-weight: bold;
  max-width: unset;
  width: unset;
  letter-spacing: 0.1em;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .content.bases .hero-title {
    font-size: 60%;
    padding-top: 5em;
  }
}
.content.bases .step-container {
  padding: 5em 9em 5em;
}
@media only screen and (max-width: 1441px) {
  .content.bases .step-container {
    padding: 8em 9em 5em;
  }
}
@media only screen and (max-width: 1025px) {
  .content.bases .step-container {
    padding: 8em 3em 5em;
  }
}
@media only screen and (max-width: 767px) {
  .content.bases .step-container {
    padding: 8em 1em 5em;
    background-size: unset !important;
  }
}
.content.bases .step-container.right-aligned .inner-container {
  text-align: right;
  margin-left: 33%;
}
@media only screen and (max-width: 767px) {
  .content.bases .step-container.right-aligned .inner-container {
    width: 100%;
    margin-left: 0px;
  }
}
.content.bases .step-container .inner-container {
  width: 66%;
  margin: 0px;
  color: #4c4c4c;
}
@media only screen and (max-width: 767px) {
  .content.bases .step-container .inner-container {
    width: 100%;
    background-color: #ffffffb3;
  }
}
.content.bases .step-container .inner-container .title {
  color: #d05041;
  font-family: "MoonFlower";
  font-size: 450%;
  font-weight: bold;
  max-width: unset;
  width: unset;
}
.content.bases .step-container .inner-container .text {
  max-width: unset;
  width: unset;
  padding: 0px;
}
.content.bases .step-container .inner-container .text img {
  width: 100%;
}
.content.bases .step-container .inner-container .text iframe {
  width: 100%;
  height: 20em;
}
.content.bases .more-info-container {
  padding: 5em 12em 5em;
}
@media only screen and (max-width: 1025px) {
  .content.bases .more-info-container {
    padding: 8em 3em 5em;
  }
}
@media only screen and (max-width: 767px) {
  .content.bases .more-info-container {
    padding: 8em 1em 5em;
  }
}
.content.bases .more-info-container .more-info-page {
  padding: 1em 0em;
}
.content.bases .more-info-container .more-info-page .inner-container {
  margin: 0px;
  color: #4c4c4c;
}
.content.bases .more-info-container .more-info-page .inner-container .title {
  font-family: "MoonFlower";
  font-size: 450%;
  font-weight: bold;
  max-width: unset;
  width: unset;
  letter-spacing: 0.02em;
  margin-bottom: 0px;
  line-height: 1;
}
.content.bases .more-info-container .more-info-page .inner-container .text {
  max-width: unset;
  width: unset;
  padding: 0em;
}
.content.jurado {
  font-size: 1.5em;
  padding-bottom: 0px;
}
@media only screen and (max-width: 1441px) {
  .content.jurado {
    font-size: 1em;
  }
}
@media only screen and (max-width: 1025px) {
  .content.jurado {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767px) {
  .content.jurado {
    font-size: 1em;
  }
}
.content.jurado .content-outer {
  width: 100%;
  padding: 10em 8em;
}
@media only screen and (max-width: 1025px) {
  .content.jurado .content-outer {
    padding: 5em;
  }
}
@media only screen and (max-width: 767px) {
  .content.jurado .content-outer {
    padding: 1em;
  }
}
.content.jurado .content-outer .content-inner {
  width: 100%;
  font-family: "AvenirNext";
  font-size: 160%;
  display: flex;
  align-items: center;
  padding-bottom: 5em;
}
@media only screen and (max-width: 767px) {
  .content.jurado .content-outer .content-inner {
    flex-direction: column;
  }
}
.content.jurado .content-outer .content-inner .bio-image-container {
  display: flex;
  flex-direction: column;
  min-width: 33%;
}
@media only screen and (max-width: 767px) {
  .content.jurado .content-outer .content-inner .bio-image-container {
    order: 0 !important;
  }
}
.content.jurado .content-outer .content-inner .bio-image-container .img-bg {
  height: 12em;
}
@media only screen and (max-width: 767px) {
  .content.jurado .content-outer .content-inner .bio-image-container .img-bg {
    height: 12em;
  }
}
.content.jurado .content-outer .content-inner .bio-image-container .title {
  font-family: "MoonFlower";
  font-size: 200%;
  text-align: center;
  line-height: 1em;
  font-weight: bold;
}
.content.jurado .content-outer .content-inner .bio-image-container.img-right {
  order: 1;
}
.content.jurado .content-outer .content-inner .bio-container {
  color: #4c4c4c;
  line-height: 110%;
  font-size: 70%;
  text-align: justify;
}
@media only screen and (max-width: 767px) {
  .content.jurado .content-outer .content-inner .bio-container {
    order: 1 !important;
  }
}
.content.jurado .content-outer .content-inner .bio-container.img-right {
  order: 0;
}
.content.subir {
  font-size: 1.5em;
  padding-bottom: 0px;
}
@media only screen and (max-width: 1441px) {
  .content.subir {
    font-size: 1em;
  }
}
@media only screen and (max-width: 1025px) {
  .content.subir {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767px) {
  .content.subir {
    font-size: 1em;
  }
}
.content.subir .content-outer {
  width: 100%;
  padding: 10em 8em;
}
@media only screen and (max-width: 1025px) {
  .content.subir .content-outer {
    padding: 5em;
  }
}
@media only screen and (max-width: 767px) {
  .content.subir .content-outer {
    padding: 1em;
  }
}
.content.subir .content-outer .content-inner {
  width: 100%;
  font-family: "AvenirNext";
  font-size: 100%;
  padding-bottom: 5em;
}

footer .footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 3em;
  font-size: 1.6em;
  background-color: white;
}
@media only screen and (max-width: 1441px) {
  footer .footer-container {
    font-size: 1.3em;
  }
}
@media only screen and (max-width: 1025px) {
  footer .footer-container {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767px) {
  footer .footer-container {
    font-size: 1em;
    flex-direction: column-reverse;
    padding: 0.5em;
  }
}
@media only screen and (max-width: 767px) {
  footer .footer-container .copyright {
    margin-top: 1em;
  }
}
footer .footer-container .credits {
  display: flex;
  justify-content: stretch;
  align-items: center;
  font-family: "MoonFlower";
  letter-spacing: 0.05em;
  color: #478248;
  font-weight: bold;
  font-size: 130%;
  text-align: right;
}
footer .footer-container .credits .signature-img {
  height: 2em;
  width: 3em;
}
@media only screen and (max-width: 767px) {
  footer .footer-container .credits .signature-img {
    flex: 1 1 50%;
  }
}

.flex-grow-shrink-0 {
  flex: 1 1 0px;
}