footer {
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 3em;
    font-size: 1.6em;
    background-color: white;
    @media only screen and (max-width: $desktop-med) {
      font-size: 1.3em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 1em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 1em;
      flex-direction: column-reverse;
      padding: 0.5em;
    }
    .copyright {
      @media only screen and (max-width: $mobile) {
        margin-top: 1em;
      }
    }
    .credits {
      display: flex;
      justify-content: stretch;
      align-items: center;
      font-family: "MoonFlower";
      letter-spacing: 0.05em;
      color: $green;
      font-weight: bold;
      font-size: 130%;
      text-align: right;
      .signature-img {
        height: 2em;
        width: 3em;
        @media only screen and (max-width: $mobile) {
          flex: 1 1 50%;
        }
      }
    }
  }
}
