
.img {
  width: 100%;
  max-height: 95vh;
  object-fit: contain;
}
.drawing-description {
  padding: 0.5em 1em;
  text-align: center;
  @media only screen and (min-width: 1200px) {
    padding-right: 20%;
    padding-left: 20%;
  }
}
.drawing-info {
  padding: 0.5em 1em;
  text-align: right;
  font-size: 80%;
  @media only screen and (min-width: 1200px) {
    padding-right: 20%;
    padding-left: 20%;
  }
}
.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    width: 30%;
    height: 30%;
  }
}
.rating-container {
  padding: 0.5em 25%;
  position: relative;
  @media only screen and (max-width: 1200px) {
    padding: 0.5em ;
  }
}
.jury-vote-title {
  font-size: 150%;
  text-align: center;
  margin-bottom: 1em;
}
.jury-vote-container {
  .jury-vote {
    padding-bottom: 3em;
    .criteria-description{
      padding-bottom: 1em;
    }
    .value-table {
      font-size: 80%;
    }
  }

}
.content-inner {
  position: relative;
}
body {
  &.modal-open {
    @media screen and (min-width: 1200px) {
      .menu-container {
        padding-right: 0px;
      }
    }
  }
}
