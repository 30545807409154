
.hero-title {
  .hero-text {
    font-size: 700%;
    font-family: "MoonFlower";
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
.pe-none {
  pointer-events: none;
}
.title-outer-container {
  margin-bottom: 5em;
}
.content {
  margin: 0px 10rem 10rem;
  @media only screen and (max-width: 1200px) {
    margin: 0px 5rem 8rem;
  }
  @media only screen and (max-width: 767px) {
    margin: 0px 1rem 6rem;
  }
  .list-container {
    position: relative;
    // .list-inner-container {
    //   // .list-item {
    //   // }
    // }
  }
}
.searchbar-container {
  margin-bottom: 2rem;
  display: flex;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  .orderby-container {
    width: 40%;
    @media only screen and (max-width: 1200px) {
      width: unset;
    }
  }
  .filter-container {
    width: 35%;
    margin-right: 0.5em;
    @media only screen and (max-width: 1200px) {
      width: unset;
    }
  }
}
