.content {
  // &.home {
  //   font-size: 1.2em;
  //   padding-bottom: 0px;
  //   @media only screen and (max-width: $desktop-med) {
  //     font-size: 0.75em;
  //   }
  //   @media only screen and (max-width: $tablet) {
  //     font-size: 0.6em;
  //   }
  //   @media only screen and (max-width: $mobile) {
  //     font-size: 0.6em;
  //   }
  //   .content-outer {
  //     width: 100%;
  //     padding: 0px 0px 0px 10em;
  //     @media only screen and (max-width: $tablet) {
  //       padding-left: 5em;
  //     }
  //     @media only screen and (max-width: $mobile) {
  //       padding-left: 0em;
  //     }
  //     .content-inner {
  //       width: 66%;
  //       font-family: "AvenirNext";
  //       padding-right: 20%;
  //       font-size: 160%;
  //       @media only screen and (max-width: $mobile) {
  //         padding: 0em 1em;
  //         width: 100%;
  //         padding-bottom: 3em;
  //         background-color: #ffffffba;
  //       }
  //       .charter-roman {
  //         font-family: "CharterRoman";
  //       }
  //       .title {
  //         font-size: 200%;
  //         line-height: 120%;
  //         text-align: center;
  //         margin-bottom: 1.5em;
  //         color: $light-blue;
  //       }
  //       p {
  //         text-align: justify;
  //         line-height: 130%;
  //       }
  //     }
      // .logos-container {
      //   width: 100%;
      //   display: flex;
      //   padding: 10em 0px 4em 0px;
      //   @media only screen and (max-width: $mobile) {
      //     padding: 0em 0px 3em 0px;
      //     background-color: #ffffffba;
      //   }
      //   .logo {
      //     flex: 1 1 0;
      //     margin: 0px 2em;
      //   }
      //   .logo-banner {
      //     width: 100%;
      //     height: 10em;
      //   }
      // }
    // }
  // }
  &.bases {
    font-size: 1.6em;
    padding-bottom: 0px;
    font-family: "AvenirNext";
    @media only screen and (max-width: $desktop-med) {
      font-size: 1.25em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 1.25em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 1em;
    }
    .hero-title {
      font-family: "MoonFlower";
      font-size: 110%;
      font-weight: bold;
      max-width: unset;
      width: unset;
      letter-spacing: 0.1em;
      text-align: center;
      @media only screen and (max-width: $mobile) {
        font-size: 60%;
        padding-top: 5em;
      }
    }
    .step-container {
      padding: 5em 9em 5em;
      // margin-bottom: -5em;
      @media only screen and (max-width: $desktop-med) {
        padding: 8em 9em 5em;
      }
      @media only screen and (max-width: $tablet) {
        padding: 8em 3em 5em;
      }
      @media only screen and (max-width: $mobile) {
        padding: 8em 1em 5em;
        background-size: unset !important;
      }
      &.right-aligned {
        .inner-container {
          text-align: right;
          margin-left: 33%;
          @media only screen and (max-width: $mobile) {
            width: 100%;
            margin-left: 0px;
          }
        }
      }
      .inner-container {
        width: 66%;
        margin: 0px;
        color: $gray;
        @media only screen and (max-width: $mobile) {
          width: 100%;
          background-color: #ffffffb3;
        }
        .title {
          color: $red;
          font-family: "MoonFlower";
          font-size: 450%;
          font-weight: bold;
          max-width: unset;
          width: unset;
        }
        .text {
          max-width: unset;
          width: unset;
          padding: 0px;
          img {
            width: 100%;
          }
          iframe {
            width: 100%;
            height: 20em;
          }
        }
      }
    }
    .more-info-container {
      padding: 5em 12em 5em;
      @media only screen and (max-width: $tablet) {
        padding: 8em 3em 5em;
      }
      @media only screen and (max-width: $mobile) {
        padding: 8em 1em 5em;
      }
      .more-info-page {
        padding: 1em 0em;
        .inner-container {
          margin: 0px;
          color: $gray;
          .title {
            font-family: "MoonFlower";
            font-size: 450%;
            font-weight: bold;
            max-width: unset;
            width: unset;
            letter-spacing: 0.02em;
            margin-bottom: 0px;
            line-height: 1;
          }
          .text {
            max-width: unset;
            width: unset;
            padding: 0em;
          }
        }
      }
    }
  }
  &.jurado {
    font-size: 1.5em;
    padding-bottom: 0px;
    @media only screen and (max-width: $desktop-med) {
      font-size: 1em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 1em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 1em;
    }
    .content-outer {
      width: 100%;
      padding: 10em 8em;
      @media only screen and (max-width: $tablet) {
        padding: 5em;
      }
      @media only screen and (max-width: $mobile) {
        padding: 1em;
      }
      .content-inner {
        width: 100%;
        font-family: "AvenirNext";
        font-size: 160%;
        display: flex;
        align-items: center;
        padding-bottom: 5em;
        @media only screen and (max-width: $mobile) {
          flex-direction: column;
        }
        .bio-image-container {
          display: flex;
          flex-direction: column;
          min-width: 33%;
          @media only screen and (max-width: $mobile) {
            order: 0 !important;
          }
          .img-bg {
            height: 12em;
            @media only screen and (max-width: $mobile) {
              height: 12em;
            }
          }
          .title {
            font-family: "MoonFlower";
            font-size: 200%;
            text-align: center;
            line-height: 1em;
            font-weight: bold;
          }
          &.img-right {
            order: 1;
          }
        }
        .bio-container {
          color: $gray;
          line-height: 110%;
          font-size: 70%;
          text-align: justify;
          @media only screen and (max-width: $mobile) {
            order: 1 !important;
          }
          &.img-right {
            order: 0;
          }
        }
      }
    }
  }
  &.subir {
    font-size: 1.5em;
    padding-bottom: 0px;
    @media only screen and (max-width: $desktop-med) {
      font-size: 1em;
    }
    @media only screen and (max-width: $tablet) {
      font-size: 1em;
    }
    @media only screen and (max-width: $mobile) {
      font-size: 1em;
    }
    .content-outer {
      width: 100%;
      padding: 10em 8em;
      @media only screen and (max-width: $tablet) {
        padding: 5em;
      }
      @media only screen and (max-width: $mobile) {
        padding: 1em;
      }
      .content-inner {
        width: 100%;
        font-family: "AvenirNext";
        font-size: 100%;
        padding-bottom: 5em;
      }
    }
  }
}
