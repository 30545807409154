.menu-sidebar {
  font-size: 1.5em;

  @media only screen and (max-width: $desktop-med) {
    font-size: 1.5em;
  }
  @media only screen and (max-width: $tablet) {
    font-size: 1em;
  }
  @media only screen and (max-width: $mobile) {
    font-size: 1em;
  }
  a {
    font-size: 200%;
    color: $light-green;
    &:hover {
      text-decoration: underline;
    }
  }
}
