
.overflow-hidden {
  overflow: hidden;
}
.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.img-container {
  position: relative;
  width: 100%;
  height: 100%;
  transition: box-shadow 250ms;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  &:hover {
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    width: 30%;
    height: 30%;
  }
}
.badge-container {
  font-size: 120%;
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  display: flex;
  .image-badge {
    margin-left: 0.5em;
    // &.public-vote {
    // }
    // &.my-me {
    // }
  }
}
