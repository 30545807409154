
$avatar-size: 5em;
.avatar-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .avatar-item {
    margin: 0.5em;
    padding: 0.5em;
    border-radius: 10%;
    height: $avatar-size;
    width: $avatar-size;
    cursor: pointer;
    &.current {
      cursor: unset;
      background-color: #d8d8d8;
    }
    &.selected {
      background-color: #aeffa3;
    }
    &:hover {
      background-color: #e6e4e4;
      &.selected {
        background-color: #aeffa3;
      }
      &.current {
        background-color: #d8d8d8;
      }
    }
  }
}
